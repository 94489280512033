var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "background-color": "#F2F2F2"
    },
    attrs: {
      "id": "home-page"
    }
  }, [_c('navigation'), _vm.banner != undefined ? _c('div', [_c('van-image', {
    attrs: {
      "width": "100%",
      "src": _vm.banner.img
    }
  })], 1) : _vm._e(), _vm._m(0), _vm.banner != undefined ? _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('van-image', {
    staticStyle: {
      "width": "93.59vw",
      "height": "37.44vw",
      "border-radius": "8px",
      "overflow": "hidden"
    },
    attrs: {
      "src": _vm.guide.img
    }
  })], 1) : _vm._e(), _vm._m(1), _vm.productList.length > 0 ? _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('ul', _vm._l(_vm.productList, function (product, index) {
    return _c('li', {
      key: index,
      attrs: {
        "id": "productCard"
      },
      on: {
        "click": function ($event) {
          return _vm.onProductClickV2(product.pageName);
        }
      }
    }, [_c('div', {
      staticStyle: {
        "width": "93.85vw",
        "height": "121.79vw",
        "background-color": "#FFFFFF",
        "border-radius": "8px",
        "margin-top": "2vw"
      }
    }, [_c('div', {
      staticStyle: {
        "height": "1px"
      }
    }), _c('div', {
      staticStyle: {
        "margin-left": "2vw",
        "margin-right": "2vw",
        "margin-top": "2vw"
      }
    }, [_c('van-image', {
      staticStyle: {
        "width": "89.85vw",
        "height": "93.33vw",
        "overflow": "hidden",
        "border-top-left-radius": "8px",
        "border-top-right-radius": "8px"
      },
      attrs: {
        "src": product.coverImg,
        "lazy-load": ""
      }
    })], 1), _c('div', {
      staticStyle: {
        "align-items": "center",
        "font-size": "20px",
        "font-weight": "bold",
        "margin-top": "4vw",
        "margin-left": "4vw",
        "text-align": "left",
        "width": "85.85vw",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
      }
    }, [_vm._v(" " + _vm._s(product.title) + " ")]), _c('div', {
      staticStyle: {
        "display": "flex",
        "align-items": "center",
        "height": "6.41vw",
        "font-size": "16px",
        "margin-left": "4vw",
        "text-align": "left",
        "width": "85.85vw",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
      }
    }, [_vm._v(" " + _vm._s(product.sellerPoint) + " ")]), _c('div', {
      staticStyle: {
        "display": "flex",
        "align-items": "center",
        "height": "6.41vw",
        "font-size": "20px",
        "font-weight": "bold",
        "margin-left": "4vw",
        "text-align": "left",
        "width": "85.85vw",
        "white-space": "nowrap",
        "overflow": "hidden",
        "text-overflow": "ellipsis"
      }
    }, [_c('span', {
      staticStyle: {
        "color": "#ff3636"
      }
    }, [_vm._v(_vm._s(product.currencyCode) + _vm._s((product.salePrice / 100).toFixed(2)))]), _vm._v("  "), _c('span', {
      staticStyle: {
        "text-decoration": "line-through",
        "font-weight": "lighter",
        "font-style": "italic",
        "color": "gray",
        "margin-left": "1vw"
      }
    }, [_vm._v(_vm._s((product.originPrice / 100).toFixed(2)))]), _c('span', {
      staticStyle: {
        "width": "21.54vw",
        "height": "6.41vw",
        "display": "flex",
        "align-items": "center",
        "justify-content": "center",
        "background-color": "#D56460",
        "margin-left": "1vw"
      }
    }, [_vm._v("   " + _vm._s(product.discount) + "   ")])])])]);
  }), 0)]) : _vm._e(), _c('information'), _vm._m(2)], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('h3', {
    staticStyle: {
      "margin-top": "2vw",
      "margin-bottom": "2vw",
      "margin-left": "4vw"
    }
  }, [_vm._v("How to Customize")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "id": "productList"
    }
  }, [_c('h3', {
    staticStyle: {
      "margin-top": "2vw",
      "margin-bottom": "0vw",
      "margin-left": "4vw"
    }
  }, [_vm._v("Product List")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "position": "fixed",
      "text-align": "center",
      "bottom": "0",
      "width": "100vw",
      "height": "6vw",
      "color": "rgb(41, 39, 39)",
      "z-index": "-1"
    }
  }, [_vm._v(" There are no more products available ~~~ "), _c('br'), _c('br')]);
}];
export { render, staticRenderFns };