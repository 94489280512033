var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "bottom-tab"
  }, [_c('div', {
    staticStyle: {
      "width": "100vw",
      "height": "12.82vw",
      "background-color": "aliceblue",
      "display": "flex",
      "justify-content": "flex-start"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "12vw",
      "height": "12.82vw",
      "margin-left": "2vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    },
    on: {
      "click": function ($event) {
        return _vm.goToHome();
      }
    }
  }, [_c('van-icon', {
    attrs: {
      "name": "wap-home-o",
      "size": "1.5rem",
      "color": _vm.colorsList[0] ? 'gray' : 'black'
    }
  })], 1), _c('div', {
    staticStyle: {
      "width": "12vw",
      "height": "12.82vw",
      "margin-left": "1vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    },
    on: {
      "click": function ($event) {
        return _vm.goToMy();
      }
    }
  }, [_c('van-icon', {
    attrs: {
      "name": "user-o",
      "size": "1.5rem",
      "color": _vm.colorsList[1] ? 'gray' : 'black'
    }
  })], 1), _vm._m(0), _c('div', {
    staticStyle: {
      "width": "12vw",
      "height": "12.82vw",
      "margin-left": "17vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }), _c('div', {
    staticStyle: {
      "width": "12vw",
      "height": "12.82vw",
      "margin-left": "1vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    },
    on: {
      "click": function ($event) {
        return _vm.goToOrderList();
      }
    }
  }, [_c('van-icon', {
    attrs: {
      "name": "orders-o",
      "size": "1.5rem",
      "color": _vm.colorsList[3] ? 'gray' : 'black'
    }
  })], 1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "width": "12vw",
      "height": "12.82vw",
      "margin-left": "17vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "16vw",
      "height": "16.82vw"
    },
    attrs: {
      "src": require("@/assets/icons/loading.svg")
    }
  })]);
}];
export { render, staticRenderFns };