var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "background-color": "#101010",
      "height": "95.89vw",
      "width": "100vw"
    }
  }, [_vm._m(0), _c('div', {
    staticStyle: {
      "background-color": "aliceblue",
      "border": "solid 0.1vw aliceblue",
      "width": "80vw",
      "margin-left": "10vw"
    }
  }), _c('div', {
    staticStyle: {
      "color": "aliceblue",
      "font-size": "4.11vw",
      "font-weight": "bold",
      "height": "10.26vw",
      "width": "100vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    },
    on: {
      "click": function ($event) {
        return _vm.goToAboutUs();
      }
    }
  }, [_vm._v(" About ")]), _c('div', {
    staticStyle: {
      "color": "aliceblue",
      "font-size": "4.11vw",
      "font-weight": "bold",
      "height": "10.26vw",
      "width": "100vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    },
    on: {
      "click": function ($event) {
        return _vm.goToPolicy();
      }
    }
  }, [_vm._v(" Policy ")]), _c('div', {
    staticStyle: {
      "color": "aliceblue",
      "font-size": "4.11vw",
      "font-weight": "bold",
      "height": "10.26vw",
      "width": "100vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    },
    on: {
      "click": function ($event) {
        return _vm.goToReturn();
      }
    }
  }, [_vm._v(" Return ")]), _c('div', {
    staticStyle: {
      "color": "aliceblue",
      "font-size": "4.11vw",
      "font-weight": "bold",
      "height": "10.26vw",
      "width": "100vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    },
    on: {
      "click": function ($event) {
        return _vm.goToRights();
      }
    }
  }, [_vm._v(" Rights ")]), _c('div', {
    staticStyle: {
      "color": "aliceblue",
      "font-size": "4.11vw",
      "font-weight": "bold",
      "height": "10.26vw",
      "width": "100vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    },
    on: {
      "click": function ($event) {
        return _vm.goToShoppingPolicy();
      }
    }
  }, [_vm._v(" ShippingPolicy ")]), _c('div', {
    staticStyle: {
      "color": "aliceblue",
      "font-size": "4.11vw",
      "font-weight": "bold",
      "height": "10.26vw",
      "width": "100vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    },
    on: {
      "click": function ($event) {
        return _vm.goToTerms();
      }
    }
  }, [_vm._v(" Terms ")]), _c('div', {
    staticStyle: {
      "color": "aliceblue",
      "font-size": "3.07vw",
      "font-weight": "bold",
      "height": "10.26vw",
      "width": "100vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_vm._v(" Copyright © [2024] [Udefine]. All rights reserved. ")]), _c('br')]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "height": "13.59vw",
      "width": "100vw",
      "margin-top": "5vw",
      "display": "flex"
    }
  }, [_c('div', {
    staticStyle: {
      "color": "aliceblue",
      "font-size": "4.11vw",
      "font-weight": "bold",
      "height": "13.59vw",
      "width": "100vw",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('a', {
    staticStyle: {
      "color": "aliceblue",
      "font-size": "4.11vw",
      "font-weight": "bold"
    },
    attrs: {
      "href": "mailto:udefine@caiseshi.cn",
      "target": "_blank"
    }
  }, [_vm._v("Contact Us")])])]);
}];
export { render, staticRenderFns };