import "core-js/modules/es.array.push.js";
import { Icon } from 'vant';
export default {
  name: 'Navigation',
  components: {
    [Icon.name]: Icon
  },
  data() {
    return {
      colorsList: [],
      navigation: 'home'
    };
  },
  created: async function () {
    this.onChange();
  },
  methods: {
    onChange() {
      this.colorsList = [false, false, false, false];
      const myUrl = window.location.pathname;
      if (myUrl.includes('home')) {
        this.colorsList[0] = true;
      } else if (myUrl.includes('myInfo')) {
        this.colorsList[1] = true;
      } else if (myUrl.includes('order')) {
        this.colorsList[3] = true;
      }
    },
    goToHome() {
      if (!window.location.pathname.includes('home')) {
        this.$router.push({
          path: '/home'
        });
      }
    },
    goToMy() {
      if (!window.location.pathname.includes('myInfo')) {
        this.$router.push({
          path: '/myInfo'
        });
      }
    },
    goToOrderList() {
      if (!window.location.pathname.includes('order')) {
        this.$router.push({
          path: '/order/list'
        });
      }
    },
    goToCar() {}
  }
};